.container{
    width: 90%;
    max-width: 1000px;
    margin: 0px auto;
}




@media only screen and (max-width: 576px) {


}