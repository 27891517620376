:root {
    --main-color: #000;
    --second-color:#6b7280;
    --border-color:#000;
    --link-color:#243277;
    --bg-color:#fff;
    --body-color:#fff;
  }
  
  [theme="dark"] {
    --main-color: #fff;
    --second-color:#7C8590;
    --border-color:#363B42;
    --link-color:#477FEF;
    --bg-color:#0D1116;
    --body-color:#000;
  }
  
body{
    background: var(--body-color);
}


.Hub{
    max-width: 960px;
 
}
.HubHeader{
    display: flex;
    width: 100%;
    margin: 0px auto;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
}
.HubLogo{
    width: 140px;
   height: 40px;
   display: flex;
   align-items: center;
}
.HubNav{
    display: flex;
    list-style-type: none;
 
}

.HubNav li{
    color: var(--main-color);
    cursor: pointer;
    width: 100px;
    text-align: center;
}
.navButtons{
    display: flex;
    align-items: center;
}
.changeTheme{
    height: 40px;
    width: 40px;
    border: 2px solid var(--border-color);
    background: var(--bg-color);
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    margin-left: 20px;
}
.navTwitter{
    height: 40px;
    width: 40px;
    border: 2px solid var(--border-color);
    background: var(--bg-color);
    border-radius: 4px;
  
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}
.menu{
    height: 40px;
    width: 40px;
    border: 2px solid var(--border-color);
    background: var(--bg-color);
    border-radius: 4px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer; 
    display: none;
}
.navMenu{
    width: 200px;
    background:var(--bg-color);
    border: 2px solid var(--border-color);
    border-radius: 4px;
}
.menuItem{
    color: var(--main-color);
    cursor: pointer;
    padding: 15px 0px;
    padding-left: 15px;
}

.menuItem:hover{
    border: none;
}
.szh-menu__item--hover {
    border: 0px;
 
 }
.HubBanner{
    color: var(--main-color);
    width: 100%;
    padding: 100px 0px;
}

.HubBanner h1{

    text-align: center;
    width: 100%;
}
.HubBanner h2{
    color: var(--second-color);
    text-align: center;
    width: 100%;  
}
.HubBannerSubmit{
    display: block;
    width: 140px;
    text-align: center;
    height: 40px;
    line-height: 36px;
    border-radius: 4px;
    border: 2px solid var(--border-color);
    background: var(--bg-color);
    color: var(--main-color);
    margin: 0px auto;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CategoryTitle{
    color: var(--main-color);
    font-size: 22px;
    font-weight: bold;
    margin-top: 100px;
}
.CategoryIntroduce{
    color: var(--main-color);
    margin-bottom: 20px;
}

.Events{
    
}

.Events li{
    margin-right: 18px;
    border: 2px solid var(--border-color);
    background: var(--bg-color);
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    width: calc(100% - 58px);
}

.EventsTitle a{
    font-size: 18px;
    color: var(--main-color);
    text-decoration: none;
}

.EventsTitle a:hover{
    text-decoration: underline;
}
.EventsDateAndLocation{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.PresentedBy,.EventsDate,.EventsLocation{
    color: var(--second-color);
}

.EventsDate span,.EventsLocation span{
    color: var(--main-color);
}
.PresentedBy{
    width: 350px;
}

.EventsDate,.EventsLocation{
    width: 250px;
}
.PresentedBy a{
    color: var(--main-color);
}

.Infras{
    display: flex;
    flex-wrap: wrap;
   
}
.Infra a,.Games a{
    color: var(--main-color);
    text-decoration: none;
}
.Infra a:hover,.Games a:hover{
    text-decoration: underline;
}
.Infras li{

    width: 258px;
    border: 2px solid var(--border-color);
    background: var(--bg-color);
    border-radius: 4px;
    padding: 20px;
    margin-right: 18px;
    margin-bottom: 20px;
}
.InfraHeader{
    display: flex;
  
}
.InfraImage{
    width: 48px;
    height: 48px;
}
.InfraName{
    font-size: 16px;

    height: 48px;
    justify-content: center;
    margin-left: 10px;
    display: flex;
    flex-direction:column;
}
.InfraDescription{
    color: var(--second-color);
    height: 80px;
    margin-top: 14px;
    font-size: 14px;
}
.InfraBuilt{
    display: flex;
    align-items: center;
    height: 30px;
    font-size: 14px;
}
.InfraMenu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.InfraMenuIcons{
    height: 28px;
    display: flex;
    align-items: center; 
}
.InfraMenuIcons a{
    margin-right: 10px;
}

.InfraMenuMore{
    cursor: pointer;
    font-size: 14px;
   text-align: center;
   width: 50px;
   height: 28px;
   line-height: 26px;
   border: 2px solid #000;
   /* background: #000; */
   color: #000;
   border-radius: 2px;
}



.Games{
    display: flex;
    flex-wrap: wrap;
   
}
.Games li{
    margin-right: 18px;
    width: 100%;
    border: 2px solid var(--border-color);
    background: var(--bg-color);
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.GamesHeader{
    display: flex;
    width: 240px;
}
.GamesImage{
    width: 48px;
    height: 48px;
}
.GamesName{
    align-items: start;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    display: flex;
    margin-left: 10px;
}
.GameTag{
    /* background: ; */
}
.GameTag a{
    font-size: 12px;
    background: var(--main-color);
    color: var(--bg-color);
    padding: 2px 2px;
    border-radius: 2px;
}
.GamesDescription{
    display: flex;
    align-items: center;
    width: 300px;
    height: 80px;
    font-size: 14px;
    color: var(--second-color);
}
.GameEngine{
    color: var(--main-color);
}
.GamesMenu{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.GamesMenuIcons{
    height: 28px;
    display: flex;
    align-items: center; 
}
.GamesMenuIcons a{
    margin-right: 10px;
}



.FrameWorkTabs{
    display: flex;
    margin-bottom: 20px;
    
}
.FrameWorkTabs li{
    cursor: pointer;
    text-align: center;
    width: 100px;
    border-radius: 4px;
    height: 30px;
    line-height: 28px;
    border: 2px solid var(--second-color);
    margin-right: 10px;
    color: var(--second-color);
}
.FrameWorkTabs .FrameWorkTabsSelected{
    cursor: pointer;
    color: var(--main-color);
    border: 2px solid var(--main-color);
}



.Articles li{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0px;
    border-bottom: 2px solid var(--main-color);
}
.Articles li p{
    width: 80%;
}
.Articles li  a{
    color:var(--second-color);
    text-decoration: none;
    text-align: right;
}
.Articles li p a{
    color: var(--main-color);
    text-decoration: none;
}
.Articles li a:hover{
    text-decoration: underline;
}

.Footer{
    color: var(--main-color);
    text-align: center;
    margin-top: 100px;
    padding-bottom: 100px;
}

.HubAuthor img{
    border-radius: 100px;
    margin-left: 6px;
    margin-right: 6px;
}
.HubAuthor{
    color: var(--main-color);
    text-decoration: none;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
}
.HubAuthor:hover{
    text-decoration: underline;
}

.Explorers{
    display: flex;
    flex-wrap: wrap;
}
.ExplorersHeader{
    align-items: center;
    display: flex
    
}
.ExplorersHeader p{
    font-size: 16px;

    margin-left: 10px;
}
.ExplorersHeader p a{
    color: var(--main-color);
    text-decoration: none;
}
.ExplorersHeader p a:hover{
    text-decoration: underline;
}
.Explorers li{
    width: 176px;
    border: 2px solid var(--border-color);
    background: var(--bg-color);
    border-radius: 4px;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.ExplorersTags{
    display: flex;
    margin-top: 14px;
}
.ExplorersTag{
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 2px;
    margin-right: 4px;
    color: var(--bg-color);
    background: var(--second-color);
}

@media only screen and (max-width: 576px) {
    .HubNav{
        display: none;
    }
    .menu{
        display: flex;
        text-align: center;

    }
    .HubBanner h1{
        font-size: 26px;
    }
    .HubBanner h2{
        font-size: 22px;
    }
    .Events li{
        margin-right: 0px;
        width:  calc(100% - 44px);
    }
    .EventsDateAndLocation{
        flex-direction: column;
    }
    .Infras li{
        margin-right: 0px;
        width: 100%;
    }
    .Games li{
        flex-direction: column;
        align-items:start;
        width: calc(100% - 40px);
        margin-right: 0px;
    }
    .GamesMenu{
        margin-top: 10px;
    }
    .Guiders li{
        width: 100%;
        margin-right: 0px;
    }
}


