@font-face {
  font-family: fac;
  src: url("./assets/font/GDSherpaBold.ttf");
}


ul,li{
  list-style-type: none;
  margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}
p{
  margin: 0px;
  padding: 0px;
}

*{
  
  font-family:fac;

}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}







code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* @import '~antd/dist/antd.css'; */
/* @import url('https://fonts.font.im/css?family=Poppins'); */
/* @import url('https://fonts.font.im/css?family=Candal'); */
